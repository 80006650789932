import * as S from "./Gallery.styles";
import Photo from "./Photo";

function Gallery() {
  const photos = [
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_1`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_2`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_3`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_4`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_5`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/gallery_6`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_7`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_8`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_9`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_10`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_11`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_12`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_13`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_14`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_15`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_16`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_17`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_18`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_19`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/mag172`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
  ];
  return (
    <S.Gallery data-test-id="Gallery">
      {photos.map(({ realSize, thumbnail }) => {
        return (
          <S.PhotoContainer
            key={realSize}
            data-test-id="Photo_Container"
            id="realizacje"
          >
            <Photo URL={realSize} thumbnail={thumbnail} />
          </S.PhotoContainer>
        );
      })}
    </S.Gallery>
  );
}

export default Gallery;
